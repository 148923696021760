<template>
  <v-container fluid full>
    <v-row wrap>
      <v-col cols="12" md="9">
        <v-row wrap>
          <v-col cols="12" md="5">
            <v-layout wrap>
              <v-img :src="product.productImagePath" contain height="300" />
            </v-layout>
            <v-row>
              <v-col
                cols="4"
                v-for="(image, index) in product.ProductImages"
                :key="index"
              >
                <v-layout style="padding:10px; background-color: #f2f2f2;">
                  <v-img :src="image.imagePath" contain height="80" />
                </v-layout>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="7" class="productStyle">
            <h2>{{ product.name | ucwords }}</h2>
            <p class="mt-5">
              Product Description: {{ product.description }}
            </p>
            <v-layout wrap>
              <v-rating
                :model="3"
                background-color="orange lighten-3"
                color="orange"
                small
              ></v-rating>
              <span
                class="black--text text--lighten-3 mr-2 ml-2 mt-2 spanStyle"
              >
                5.0
              </span>
              <span
                class="black--text text--lighten-2 text--lighten-3 mr-2 ml-2 mt-2 spanStyle"
              >
                {{ product.ProductReviews.length }} Reviews
              </span>

              <span
                class="black--text text--lighten-2 text--lighten-3 mr-2 ml-2 mt-2 spanStyle"
              >
                {{ product.quantity }} Orders Available
              </span>
            </v-layout>
            <!-- <h2 class="mt-4 priceTag">
              {{ systemSetting.systemCurrency }}{{ product.price }}
            </h2> -->
            <div class="mt-5">
              <p>
                <b>Store:</b>
                <span class="ml-5" v-if="product.User.Retailer !== null">
                  <router-link :to="'/store/0/' + product.User.userId" class="linkStyle">{{ product.User.Retailer.companyName }}</router-link>
                </span>
                <span class="ml-5" v-if="product.User.Manufacturer !== null">{{ product.User.Manufacturer.companyName }}</span>
                <span class="ml-5" v-if="product.User.Manufacturer !== null">{{ product.User.Manufacturer.companyName }}</span>
              </p>
              <p><b>Availability:</b> <span class="ml-5">In Stock</span></p>
              <p>
                <b>Product Category:</b>
                <span class="ml-5">{{ product.ProductCategory.name }}</span>
              </p>
              <p>
                <b>Quantity:</b>
                <span class="ml-5">
                  <v-icon
                    dark
                    color="#72bd61"
                    class="mr-3"
                    @click="decreamentCounter"
                    >mdi-minus-circle</v-icon
                  >
                  {{ cartData.quantity }}
                  <v-icon
                    dark
                    color="#72bd61"
                    class="ml-3"
                    @click="increamentCounter"
                    >mdi-plus-circle</v-icon
                  >
                </span>
              </p>
              
              <p class="mt-5" style="color:#F01010">
                <em>Please Note:<br/>
                <ul>
                  <li>Quantity supplies are measured in cartoons for only Distributor and Retailers</li>
                </ul>
                </em>
              </p>
            </div>
            <v-divider></v-divider>

            <div>
              <v-btn
                v-if="isAuth"
                class="buttonStyle mr-5 ml-5"
                @click="addCart"
              >
                Add to Cart
              </v-btn>


              <v-dialog v-model="dialogLogin" width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="!isAuth"
                    class="buttonStyle mr-5 ml-5"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Add to Cart
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-row wrap>
                      <v-col cols="8" offset="2">
                        <v-row>
                          <v-col cols="12" align="center" justify="center">
                            <span class="titleStyleColor">Sign</span>
                            <span class="titleStyle"> In</span><br />
                            <router-link
                              :to="'/registration'"
                              style="text-decoration: none; color: black;"
                            >
                              <v-icon>mdi-account-lock-outline</v-icon>
                              <span style="color:#7CBF46">
                                Don't have an account?</span
                              >
                            </router-link>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-progress-linear
                            color="#7CBF46"
                            v-show="loader"
                            :indeterminate="true"
                          ></v-progress-linear>
                          <v-col cols="12">
                            <v-row>
                              <v-text-field
                                v-model="login.email"
                                label="Email"
                                append-icon="mdi-email"
                                outlined
                                dense
                                color="#7CBF46"
                              ></v-text-field>
                            </v-row>

                            <v-row>
                              <v-text-field
                                name="password"
                                label="Password"
                                outlined
                                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show3 ? 'text' : 'password'"
                                id="password"
                                dense
                                v-model="login.password"
                                @click:append="show3 = !show3"
                                color="#7CBF46"
                              ></v-text-field>
                            </v-row>

                            <v-layout color="#BD2121">
                              <h5 class="text-danger" align="center">
                                {{ errorMessage }}
                              </h5>
                            </v-layout>

                            <v-layout>
                              <v-btn
                                align="center"
                                class="buttonStyle"
                                @click.native="signin"
                                block
                              >
                                Sign In
                              </v-btn>
                            </v-layout>

                            <v-row>
                              <v-col cols="12" align="center" justify="center">
                                <router-link
                                  :to="{
                                    name: 'pharmaResetPasswordLink',
                                  }"
                                  style="text-decoration: none;"
                                >
                                  <span style="color:#7CBF46"
                                    >Forgot Password?</span
                                  >
                                </router-link>
                              </v-col>
                            </v-row>

                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogAddToCart" width="700" persistent>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="11" align="center">
                        <v-icon size="60" color="#72BD61"
                          >mdi-check-circle</v-icon
                        >
                        <br />
                        <span style="font-size:16px;">
                          {{ product.name }} has been added to your shopping
                          cart.
                        </span>
                      </v-col>

                      <v-col cols="1" align="right">
                        <v-btn
                          icon
                          color="black"
                          @click="dialogAddToCart = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" align="center">
                        <v-btn class="buttonStyle" :to="'/cart/overview'">
                          View Shopping Cart</v-btn
                        >
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <h6>Buyer who bought {{ product.name }} also bought:</h6>
                    <v-row>
                      <v-col cols="12">
                        <v-sheet class="mx-auto" max-width="800">
                          <v-slide-group
                            class="pa-4"
                            active-class="success"
                            show-arrows
                          >
                            <v-slide-item
                              v-for="(recommendedProduct,
                              index) in recommendedProducts"
                              :key="index"
                            >
                              <v-card
                                color="#FAFAFA"
                                class="ma-4"
                                height="180"
                                width="150"
                              >
                                <v-row class="fill-height">
                                  <router-link
                                    :to="'/product-detail/' + recommendedProduct.productId"
                                    style="text-decoration:none !important;"
                                  >
                                    <v-img
                                      height="100"
                                      :src="recommendedProduct.productImagePath"
                                      contain
                                      style="background-color:#ffffff;"
                                    >
                                      <!-- <h6 align="center" class="priceStyle2">
                                        {{ systemSetting.systemCurrency }}
                                        {{ recommendedProduct.price }}
                                      </h6> -->
                                    </v-img> 
                                  </router-link>
                                  <p class="ma-2">
                                    {{ recommendedProduct.name }}
                                    <br />
                                    <span
                                      ><b>
                                        {{ systemSetting.systemCurrency }}
                                        {{ recommendedProduct.price | numberFormat }}</b
                                      >
                                    </span>
                                  </p>
                                </v-row>
                              </v-card>
                            </v-slide-item>
                          </v-slide-group>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <!--- Recommendation Product Layout ---->
      <v-col cols="12" md="3" class="hidden-sm-and-down">
        <h5 style="color:#c4c4c4;">Recommended for you</h5>
        <v-layout wrap class="reProductDiv" v-if="recommendedProducts.length >=1">
          <v-col
            cols="12"
            class="reProduct"
            v-for="(recommendedProduct, index) in recommendedProducts.slice(
              0,
              3
            )"
            :key="index"
          >
            <div>
              <router-link
                :to="'/product-detail/' + recommendedProduct.productId"
                style="text-decoration:none !important;"
              >
                <v-img
                  height="100"
                  :src="recommendedProduct.productImagePath"
                  contain
                  style="background-color:#ffffff;"
                >
                  <h6 align="center" class="priceStyle2">
                    {{ systemSetting.systemCurrency }}
                    {{ recommendedProduct.price | numberFormat }}
                  </h6>
                </v-img> 
              </router-link>
            </div>
            <p align="center">{{ recommendedProduct.name }}</p>
          </v-col>
        </v-layout>
      </v-col>
    </v-row>

    <v-row wrap class="mt-5 mb-3 overviewContainer">
      <v-col cols="12">
        <!---- Product Overviews and Reviews ---->
        <v-row wrap>
          <v-col cols="12">
            <v-card color="basil">
              <v-tabs
                v-model="tab"
                background-color="#ffffff"
                color="basil"
                grow
              >
                <v-tab>
                  OVERVIEW
                </v-tab>
                <v-tab>
                  CUSTOMER REVIEWS {{ product.ProductReviews.length }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card color="#ffffff" flat>
                    <v-card-text>
                       <v-row>
                        <v-col cols="12">
                          {{ product.description }}
                        </v-col>
                       </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          v-for="(overview, index) in product.productOverviews"
                          :key="index"
                          class="pa-5"
                        >
                          <h5>{{ overview.topic }}</h5>
                          <p>
                            {{ overview.description }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card color="#ffffff" flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <h6>
                            Customer Reviews ({{
                              product.ProductReviews.length
                            }})
                          </h6>
                          <div>
                            <v-simple-table fixed-header height="500px">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      Customer
                                    </th>
                                    <th class="text-left">
                                      Reviews
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item,
                                    index) in product.ProductReviews"
                                    :key="index"
                                  >
                                    <td>
                                      <p>
                                        {{ product.User.name }}
                                        <br />
                                        <span style="font-size:10px;">
                                          from
                                          {{ product.User.country }}</span
                                        >
                                      </p>
                                    </td>
                                    <td>
                                      <v-rating
                                        v-model="item.rating"
                                        background-color="orange lighten-3"
                                        color="orange"
                                        small
                                      ></v-rating>
                                      <p>
                                        {{ item.review }}
                                        <br />
                                        <span style="font-size:10px;">
                                          {{ item.createdAt | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}
                                          </span
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Country } from "country-state-city";
export default {
  name: "SingleProduct",
  props: {
    cartItems: Array,
    isAuth: Boolean,
  },
  data() {
    return {
      dialogLogin: false,
      dialogShipping: false,
      dialogAddToCart: false,
      tab: null,
      country: [],
      product: {
        productId: "",
        name: "",
        User: {
          name: "",
        },
        productImagePath: "",
        ProductReviews: [],
        ProductCategory: {
          productCategoryId:"",
          name: "",
        },
      },
      recommendedProducts: [],
      systemSetting: {
        systemCurrency: "₦",
      },
      storeDetail: null,
      cartData: {
        productId: "",
        quantity: 1,
      },
      user_id: "",
      categoryItems: [],
      loader: false,
      checkbox: false,
      errorMessage: "",
      show3: false,
      login: {
        email: "",
        password: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      userDetails:{},
    };
  },
  created() {
    const self = this;
    self.fetchUserDetail();
    self.getCountry();
    self.fetchProduct();
    self.getProductCat();
    self.getRecommendedProducts();
  },
  methods: {
    getProductCat() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
          this.categoryItems = response.data.data;
        });
    },
    getRecommendedProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}products`)
        .then((response) => {
          response.data.data.rows.forEach(element => {
                if(element.User.Role.name == "Retailer" ){
                        this.recommendedProducts.push(element);   
                }
          });
        });
    },
    fetchProduct() {
      this.$http
        .get(
          `${process.env.VUE_APP_URL}product/` + this.$route.params.productId
        )
        .then((response) => {
          this.product = response.data.data;

          if(response.data.data.User.Distributor != null)
          {
            this.storeDetail = response.data.data.User.Distributor;
          }
          
          if(response.data.data.User.Manufacturer != null)
          {
            this.storeDetail = response.data.data.User.Manufacturer;
          }
          
          if(response.data.data.User.Retailer != null)
          {
            this.storeDetail = response.data.data.User.Retailer;
          }

          this.storeDetail = response.data.data.User.store;
          this.user_id = response.data.data.User.userId;
          this.cartData.productId = this.$route.params.productId;

          let recentlyProducts = JSON.parse(
            localStorage.getItem("recentlyViewedProducts")
          );

          if (recentlyProducts == null) recentlyProducts = [];
          const product = this.product;
          localStorage.setItem("product", JSON.stringify(product));
        
          // Save product back to local storage
          for (var i = 0; i < recentlyProducts.length; i++) {
            if (recentlyProducts[i].productId !== product.productId) {
              recentlyProducts.push(product);
              localStorage.setItem(
                "recentlyViewedProducts",
                JSON.stringify(recentlyProducts)
              );
            }
          }
        });
    },
    fetchUserDetail() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem('userId'))
        .then((response) => {
          this.userDetails = response.data.data;
        });
    },
    
    addCart() {
      this.$store.dispatch("addToCart", this.cartData);
      this.dialogAddToCart = true;
    },
    increamentCounter() {
      this.cartData.quantity = this.cartData.quantity + 1;
    },
    decreamentCounter() {
      if (this.cartData.quantity <= 0) {
        this.cartData.quantity = 1;
      }
      this.cartData.quantity = this.cartData.quantity - 1;
    },
    getCountry() {
      this.country = Country.getAllCountries();
    },
    signin() {
      this.loader = true;
      this.errorMessage = "";
      this.$http
        .post(`${process.env.VUE_APP_URL}login`,this.login,{
          headers: {
            'apiKey':`${process.env.VUE_APP_KEY}`
          }
        })
        .then((response) => {
          if (response.data.success == true) {
            localStorage.setItem("userId", response.data.data.userId);
            
            if(response.data.data.isTwoFA)
            {
              this.$router.push({
              name: "pharma2faVerification",
              params: { email: response.data.data.email }
            });
            }
            else 
            {
              if (response.data.data.Role.name == "Admin") {
                this.$auth.setToken(response.data.data.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                localStorage.setItem("userId", response.data.data.userId);
                this.$router.push({
                  name: "pharmaAdminDashboard",
                });
              }
              if (response.data.data.Role.name == "User") {
                this.$auth.setToken(response.data.data.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                localStorage.setItem("userId", response.data.data.userId);
                this.$router.push({
                  name: "pharmaUserDashboard",
                });
              }
              if (response.data.data.Role.name == "Manufacturer") {
                this.$auth.setToken(response.data.data.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                localStorage.setItem("userId", response.data.data.userId);
              
                this.$router.push({
                  name: "pharmaManufacturerDashboard",
                });
              }
              if (response.data.data.Role.name == "Distributor") {
                this.loader = false;
                this.$auth.setToken(response.data.token.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                localStorage.setItem(
                  "distributorId",
                  response.data.data.Distributor.distributorId
                );
                this.$router.push({
                  name: "pharmaDistributorDashboard",
                });
              }
              if (response.data.data.Role.name == "Retailer") {
                this.loader = false;
                this.$auth.setToken(response.data.data.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                this.$router.push({
                  name: "pharmaRetailerDashboard",
                });
              }
              if (response.data.data.Role.name == "Professional") {
                this.loader = false;
                this.$auth.setToken(response.data.data.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                this.$router.push({
                  name: "pharmaProfessionalDashboard",
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },
  },
  filters: {
      ucwords(value) {
        if (!value) return '';
        value = value.toString();
        return value.replace(/\b\w/g, function (l) {
          return l.toUpperCase();
        });
      },
      numberFormat(value) {
        if (!value) return '0';
        return new Intl.NumberFormat().format(value);
      }
  }
};
</script>

<style>
.productStyle {
  padding: 0px 35px 15px 35px;
}

.reProductDiv {
  background-color: #f2f2f2;
  padding: 15px 25px 25px 25px;
}

.reProduct {
  background-color: #ffffff;
  padding: 10px;
  margin-top: 10px;
}

.priceStyle {
  width: 80px;
  height: auto;
  background-color: black;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.priceStyle2 {
  width: 70px;
  font-size: 12px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 3px;
  border-bottom-right-radius: 5px;
}

.priceStyle3 {
  width: 100px;
  font-size: 12px;
  height: auto;
  background-color: #198754;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.spanStyle {
  font-size: 14px !important;
}

.priceTag {
  color: #72bd61;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 5px;
  color: #ffffff !important;
}

.buttonStyleGray {
  background: linear-gradient(192.88deg, #ffffff, #111212 90.69%);
  border-radius: 5px;
  color: #ffffff !important;
  /* font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
  padding-top: 30px;
  padding-bottom: 30px; */
}

.overviewContainer {
  background-color: #f2f2f2;
  padding: 10px;
}

.whiteBg {
  background-color: #ffffff;
  padding: 20px;
}

.greyBg {
  background-color: #0f0e0e;
  padding: 20px;
}

.titleStyle {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
}

.titleStyleColor {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.productImg {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 200px;
}

.linkStyle {
  text-decoration: none;
}

</style>
